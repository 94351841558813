import Vue from 'vue'
import Router from 'vue-router'
import { META } from './assets/javascript/meta'
import * as _ from 'lodash'

const IDE = () => import('./views/IDE')

Vue.use(Router)

let MainChildren = [
  {
    path: '/',
    name: 'generalHome',
    component: () => import('./views/Maintenance'),
    meta: META.default
  },
  {
    path: '/terms/',
    name: 'terms',
    component: () => import('./views/Maintenance'),
    meta: META.default
  },
  {
    path: '/compiler-api/',
    name: 'compilerAPI',
    component: () => import('./views/Maintenance'),
    meta: META.api
  },
  {
    path: '/compiler-ide-plugin/',
    name: 'compilerIdePlugin',
    component: () => import('./views/Maintenance'),
    meta: META.api
  },
  {
    path: '/guru/',
    component: () => import('./views/Maintenance'),
    meta: META.guru
  },
  {
    path: '/education-institutions',
    name: 'educationInstitutions',
    component: () => import('./views/Maintenance'),
    meta: META.default
  },
  {
    path: '/contact-us',
    name: 'contactUs',
    component: () => import('./views/Maintenance'),
    meta: META.default
  },
  {
    path: '/a/:shareId',
    name: 'share',
    component: IDE,
    meta: Object.assign({}, META.default, {
      isShared: true,
      isInstantShare: false
    })
  },
  {
    path: '/ia/:shareId',
    name: 'ishare',
    component: IDE,
    meta: Object.assign({}, META.default, {
      isShared: true,
      isInstantShare: true
    })
  },
  {
    path: '/html-css-javascript-online-editor/',
    name: 'html',
    component: () => import('./views/Maintenance'),
    meta: META.html
  },
  {
    path: '/h/:shareId',
    name: 'htmlShare',
    component: () => import('./views/HTMLIDE'),
    meta: Object.assign({}, META.html, { isShared: true })
  },
  {
    path: '/register/verifyRegistration',
    name: 'verifyRegistration',
    component: () => import('./views/Maintenance'),
    meta: META.default
  },
  {
    path: '/register/resetPassword',
    name: 'resetPassword',
    component: () => import('./views/Maintenance'),
    meta: META.default
  },
  {
    path: '/online-mysql-terminal/',
    name: 'mysqlTerminal',
    component: () => import('./views/Maintenance'),
    meta: META.mysql
  },
  {
    path: '/online-mongodb-terminal/',
    name: 'mongoTerminal',
    component: () => import('./views/Maintenance'),
    meta: META.mongo
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('./views/Maintenance'),
    meta: META.default
  }
]

let paths = []

for (let key in META) {
  let lang = META[key]
  if (lang.isCompile === true || lang.isCompile === false) {
    paths.push('/' + lang.canonicalPath + '/')
    MainChildren.push({
      path: '/' + lang.canonicalPath + '/',
      name: lang.langDisplayName,
      component: IDE,
      meta: lang
    })

    if (lang.isPlus) {
      let langPlus = _.cloneDeep(lang)
      langPlus.isAdvanced = true
      langPlus.basicPath = langPlus.canonicalPath
      langPlus.canonicalPath = langPlus.canonicalPath + '-ide'
      paths.push(langPlus.canonicalPath)
      MainChildren.push({
        path: '/' + langPlus.canonicalPath + '/',
        name: langPlus.langDisplayName + ' Advanced IDE',
        component: import('./views/Maintenance'),
        meta: langPlus
      })
    }
  }
}

// console.log(paths)

let routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('./views/Maintenance'),
    children: MainChildren
  },
  {
    path: '/langs-and-versions-table/',
    name: 'langsAndVersionsTable',
    component: () => import('./views/LangAndVersionsTable')
  },
  {
    path: '/embed/',
    name: 'embed',
    component: () => import('./views/EmbedApp'),
    children: [
      {
        path: '/embed/v0/:shareId',
        name: 'embedIde',
        component: () => import('./views/EmbedIDE'),
        meta: { isInstantShare: false, isPlugin: false }
      }
    ]
  },
  {
    path: '/iembed/',
    name: 'iembed',
    component: () => import('./views/EmbedApp'),
    children: [
      {
        path: '/iembed/v0/:shareId',
        name: 'iembedIde',
        component: () => import('./views/EmbedIDE'),
        meta: { isInstantShare: true, isPlugin: false }
      }
    ]
  },
  {
    path: '/plugin/',
    name: 'ideplugin',
    component: () => import('./views/EmbedApp'),
    children: [
      {
        path: '/plugin/',
        name: 'simple',
        component: () => import('./views/EmbedIDE'),
        meta: { isInstantShare: false, isPlugin: true }
      }
    ]
  },
  {
    path: '/mobile-app/',
    name: 'mobile',
    component: () => import('./views/mobile/MobileApp'),
    children: [
      {
        path: '/mobile-app/login/:token',
        name: 'mobileLogin',
        component: () => import('./views/mobile/MobileLogin')
      },
      {
        path: '/mobile-app/login-success/:token',
        name: 'mobileLoginSuccess',
        component: () => import('./views/mobile/MobileLoginSuccess')
      }
    ]
  }
]

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior () {
    window.scrollTo(0, 0)
  }
})
